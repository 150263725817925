// IMPORTANT: Verze cache - po změnění SW změnit verzi pro force update!!
var version = "v0.2.2"
var appFiles = [
    '/',
    '/src.e31bb0bc.css', '/src.e31bb0bc.js',
    '/manifest.webmanifest', '/service-worker.js',
    '/fa-brands-400.34cc846b.woff2', '/fa-regular-400.82c42f2f.woff2', '/fa-solid-900.55d5ef42.woff2',
    '/icon-128x128.b41cf25b.png', '/icon-512x512.640e23eb.png'
]
appFiles = ['/'];
var waitingWorker = null;

self.addEventListener("install", function (event) {
    //event.waitUntil(self.skipWaiting()); // Activate worker immediately
    // event.waitUntil(
    //     caches
    //         .open(version + 'dist')
    //         .then(cache => cache.addAll(appFiles))
    //         .then(function () { })
    // );
});
self.addEventListener('message', function (event) {
    if (event.data.action === 'skipWaiting') {
        self.skipWaiting();
    }
});
self.addEventListener("fetch", function (event) {
    // ignore other then GET 
    if (event.request.method !== 'GET') {
        return;
    }

    const url = new URL(event.request.url);

    // ignore API
    if (url.pathname.indexOf("/api/") == 0) { return; }
    // ignore localhost DEV
    if (url.origin == "https://127.0.0.1:1234" || url.origin == "https://localhost:5001") { return; }

    event.respondWith(
        caches
            .match(event.request)
            .then(function (cached) {
                if (cached) return cached;

                var networked = fetch(event.request)
                    .then(fetchedFromNetwork, unableToResolve)
                    .catch(unableToResolve);
                return networked;

                function fetchedFromNetwork(response) {
                    var cacheCopy = response.clone();
                    // TODO: vymyslet jak lepe road-spector.at
                    var cacheName = (url.origin.indexOf("road-spector.at")  >= 0 || url.origin.indexOf("pms-it.")  >= 0 ) ? (version + 'dist') : url.host;
                    caches
                        .open(cacheName)
                        .then(cache => cache.put(event.request, cacheCopy));
                  
                    return response;
                }

                function unableToResolve() {
                    return new Response('<h1>Service Unavailable</h1>', {
                        status: 503,
                        statusText: 'Service Unavailable',
                        headers: new Headers({
                            'Content-Type': 'text/html'
                        })
                    });
                }
            })
    );
});

self.addEventListener("activate", function (event) {
    event.waitUntil(
        Promise.all([
            // Become available to all pages
            self.clients.claim(),

            // delete old app versions cache
            caches.keys().then(function (keys) {
                var oldVersion = keys.filter(key => !key.startsWith(version) && key.endsWith("dist"));
                var deleteOldVersion = oldVersion.map(key => caches.delete(key));
                return Promise.all(deleteOldVersion);
            })
        ])
    );
});